.large-spinner.loading {
  height: 4rem;
  width: 4rem;
  border-width: 6px;
  border-style: solid;
  border-color: transparent #54a0fc #54a0fc;
  border-radius: 100%;
  animation: rotation 1.2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
